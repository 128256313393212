import clsx from 'clsx';
import type { ReactElement } from 'react';

import { BaseBlock } from '@/components/base-block';
import { BlockHeader } from '@/components/block-header';
import { Colors, REWARD_CATEGORIES_DATA } from '@/lib/constants';
import { LinkListWithIconsBlock } from '@/components/link-list-block/link-list-with-icons';
import { LinkListWithImagesBlock } from '@/components/link-list-block/link-list-with-images';
import type { LinkListBlockType, LinkListIconItemType, LinkListItem, ModelCard, RewardCard } from '@/lib/types';

const isImageItem = (item: LinkListItem): item is ModelCard => {
  return item.type !== 'link_list_icon_item';
};

const isIconItem = (item: LinkListItem): item is LinkListIconItemType => {
  return item.type === 'link_list_icon_item';
};

const isRewardItem = (item: LinkListItem): item is RewardCard => {
  return item.type === 'reward';
};

const isRewardOrIconItem = (item: LinkListItem): item is LinkListIconItemType | RewardCard => {
  return isIconItem(item) || isRewardItem(item);
};

const transformImageItems = (items: LinkListItem[]): ModelCard[] => items.filter(isImageItem);

const transformIconItems = (items: LinkListItem[]): LinkListIconItemType[] => {
  const iconItems: LinkListIconItemType[] = items.filter(isRewardOrIconItem).map((item) => {
    if (isRewardItem(item)) {
      const { colour } = REWARD_CATEGORIES_DATA[item?.categoryPage?.slug] || { color: Colors.ActiveBlue };

      return {
        ...item,
        iconColour: colour,
        type: 'link_list_icon_item',
        link: item,
      };
    }
    return item;
  });

  return iconItems;
};

const LinkListBlock = ({
  title,
  description,
  contentPosition,
  theme,
  layout,
  items,
  anchor,
}: LinkListBlockType): ReactElement | null => {
  const hasSomeImageItems = items.some(isImageItem);
  const hasSomeIconItems = items.some(isIconItem);

  if (hasSomeImageItems && hasSomeIconItems) {
    return null;
  }

  const isLinkListWithIcons = items.every(isRewardOrIconItem);
  // All link list with icons are of narrow width and only linked list with bg-alt are narrow
  const blockWidth = !isLinkListWithIcons && theme === 'Light' ? 'Normal' : 'Narrow';
  const blockLayoutClass = layout.toLowerCase();
  const baseClass = isLinkListWithIcons ? 'icon-link-list link-list-with-icons' : 'link-list-with-images';

  return (
    <BaseBlock className={clsx(baseClass, blockLayoutClass)} width={blockWidth} theme={theme} anchor={anchor}>
      <BlockHeader title={title} description={description} />
      {isLinkListWithIcons ? (
        <LinkListWithIconsBlock
          blockAlignmentClass={contentPosition === 'Left' ? 'align-left' : 'align-center'}
          iconItems={transformIconItems(items)}
        />
      ) : (
        <LinkListWithImagesBlock imageItems={transformImageItems(items)} />
      )}
    </BaseBlock>
  );
};

export { LinkListBlock };
